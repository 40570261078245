.App {
  text-align: center;
}

#TuneIn * {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
/*  z-index: -100;*/
}

.video-bg:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: var(--video-overlay);
}

.video-bg {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
/*  transform: translateZ(0px);*/
}

.bgImage {
  background: url("assets/thelake.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.bgImage:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(253, 252, 242, 0.75);
}

.comeText {
  z-index: 9;
}
.phil {
/*  top: 0;*/
  min-height: 100vh;
  vertical-align: middle;
/*  z-index: 5;*/
  position: relative;
  overflow: hidden;
/*  z-index: 6;*/
  overflow-y: hidden;
}

.phil p {
  overflow-y: hidden;
}

.purpose {
  top: 0;
  vertical-align: middle;
/*  z-index: 5;*/
  position: relative;
  overflow: hidden;
/*  z-index: 6;*/
}

.parallax-container {
  background-size: cover;
  /*  this is where the magic happens:  */
  background-attachment: fixed;
  background-position: auto;

  /* dimensions are important, ensure it is tall enough to scroll */
  min-height: 100vh;

  /* you are free to lay out the container items with flexbox or whatever means you wish */
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*  align-items: center;*/
  /*  justify-content: center;*/
  /*  overflow: hidden;*/
  z-index: 1;
}

.sideText {
  z-index: 5;
}

.parallexReactOverwrite {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#fixed {
  height: 100vh;
  z-index: 0;
  left: 0;
}

.parallaxFixed {
  position: fixed;
}

#kaiOnLakeImage {
  height: 100%;
  overflow: hidden;
}

#filler {
  height: 100vh;
  visibility: hidden;
}

#section1 {
  z-index: 0;
}
#section2 {
  z-index: 2;
}

#phil1 {
  z-index: 1;
}

.parallaxOuterContainer {
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.parallaxContainer {
  width: 100%;
  height: 100%;
}

.parallaxContainer {
  width: 100%;
  height: 100%;
}

.parallaxInnerContainer {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.parallaxInnerContainer img {
  position: absolute;
  object-fit: cover;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.sideText {
  z-index: 100;
}

.purpose {
/*  z-index: 90;*/
}

#Reachout {
  position: relative;
}

.navLink {
  overflow-y: hidden;
}

#nav {
  height: 100px;
}

#rotatingBody {
  display: grid;
  justify-content: center;
  width: 100vw;
  text-align: center;
}

.main {
  background: url("assets/heal.jpeg") no-repeat center center fixed;
  background: url("assets/heal2.png") no-repeat center center fixed;
  margin-top: 40px;
  width: 700px;
  height: 700px;
  background-color: #f2f7f2;
  border-color: #565455;
  border-radius: 50%;
  border-width: 15px;
  display: grid;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-position: center center;
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.5);
}

.main:hover .item {
  animation-play-state: paused;
}

.main > * {
  grid-column: 1;
  grid-row: 1;

}

.theSmiley {
  align-self: center;
  justify-self: center;
  width: 100px;
  height: 100px;
  display: none;
}

.item {
  animation: spinAround 15s linear infinite;
  writing-mode: vertical-lr;
  display: inline-block;
}



@keyframes spinAround {
  from {
    transform: rotate(0deg) translate(250px);
  }
  to {
    transform: rotate(360deg) translate(250px);
  }
}

img.rotateIcon {
  transform: rotate(90deg);
}

@media only screen and (max-width: 62em) {
  @keyframes spinAround {
    from {
      transform: rotate(0deg) translate(110px);
    }
    to {
      transform: rotate(360deg) translate(110px);
    }
  }
  .main {
    width: 375px;
    height: 375px;
    border-width: 4px;
    margin-left: 10px;
    background: url("assets/heal_mobile.jpeg") no-repeat center center;
  }
  .theSmiley {
    display: none;
    width: 40px;
    height: 40px;
  }
}

.slider1 {
  position: absolute;
  top: 0;
  left: 35%;
  height: 100%;
  width: 35%;
  overflow: hidden;
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
}

.slider2 {
  position: absolute;
  top: 0;
  right: 35%;
  width: 35%;
  overflow: hidden;
  -webkit-transition: width 1s ease-out;
  -moz-transition: width 1s ease-out;
  -o-transition: width 1s ease-out;
  transition: width 1s ease-out;
  height: 100%;
}

.slided {
  width: 0%;
}

.step {
  min-height: 758px;
}
